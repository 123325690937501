import { Customer, MutationCustomerUpdateArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import { useValidation } from "vue3-form-validation";
import { trim } from "@/graphql/utils/utils";
import { validMail } from "@/graphql/utils/send-mail";

type UpdateCustomerData = {
  customerUpdate: Customer;
};

const MUTATION = gql`
    mutation CustomerUpdate($input: CustomerUpdateInput!) {
        customerUpdate(input: $input) {
            ${CUSTOMER_FIELDS}
        }
    }
`;

export const useUpdateCustomer = (customer: Customer, callback: () => void) => {
  function emailOk(n: string) {
    if (!n) return true;
    return validMail(n);
  }
  const { form, validateFields, resetFields } = useValidation({
    id: {
      $value: customer.id,
    },
    name: {
      $value: customer.name,
      $rules: [(n: string) => !n && ""],
    },
    phone: {
      $value: customer.phone,
    },
    email: {
      $value: customer.email,
      $rules: [(n: string) => !emailOk(n) && ""],
    },
    address: {
      $value: customer.address,
    },
  });
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onDone } = useMutation<
    UpdateCustomerData,
    MutationCustomerUpdateArgs
  >(MUTATION);

  onDone(({ data, errors }) => {
    if (errors?.length) {
      let detail = t("update.failed");
      if (errors[0].message.includes("email"))
        detail = t("customer.exists", { contact: form.email.$value });
      else if (errors[0].message.includes("phone"))
        detail = t("customer.exists", { contact: form.phone.$value });
      toast.add({
        severity: "error",
        summary: t("update.title"),
        detail,
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      toast.add({
        severity: "success",
        summary: t("update.title"),
        detail: t("update.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      callback();
    }
  });
  function updateCustomer() {
    validateFields()
      .then((input) => {
        trim(input);
        void mutate({ input });
      })
      .catch(() => ({}));
  }
  return { form, loading, updateCustomer, resetFields };
};
